














import { Component, Mixins, Prop } from 'vue-property-decorator'
import { ResourceActionFailed } from '@movecloser/front-core'

import { FormErrorsMixin } from '../../../../support/mixins'
import { Inject } from '../../../../support'

import { ILoyaltyService, LoyaltyServiceType } from '../../contracts/services'
import { LoyaltyPayload } from '../../contracts/loyalty'

import { BenefitProgramJoinFormProps } from './BenefitProgramJoinForm.contracts'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component({ name: 'BenefitProgramJoinForm' })
export class BenefitProgramJoinForm extends Mixins(FormErrorsMixin) {
  @Inject(LoyaltyServiceType)
  protected readonly loyaltyService!: ILoyaltyService

  @Prop({ type: Object, required: true })
  public readonly program!: BenefitProgramJoinFormProps['program']

  @Prop({ type: Object, required: true })
  public readonly payload!: BenefitProgramJoinFormProps['payload']

  public cardNumber: string = ''
  public loading: boolean = false

  public async onSubmit (): Promise<void> {
    this.loading = true

    try {
      // set card number for current program, null for others
      const payload = Object.keys(this.payload).reduce((acc, program) => ({
        ...acc,
        [program]: program === this.program.id ? this.cardNumber : null
      }), {}) as Pick<LoyaltyPayload, 'kameleon' | 'milesAndMore'>

      await this.loyaltyService.update({
        kameleon: payload.kameleon,
        milesAndMore: payload.milesAndMore
      } as Pick<LoyaltyPayload, 'kameleon' | 'milesAndMore'>)

      this.onSuccess()
    } catch (e) {
      this.errors = { cardNumber: [(e as ResourceActionFailed).message] }
    } finally {
      this.loading = false
    }
  }

  public onSuccess (): void {
    this.$emit('success', this.cardNumber)
  }
}

export default BenefitProgramJoinForm
