



































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Inject } from '../../../../../../support'
import { ISiteService, SiteServiceType } from '../../../../../../contexts'
import {
  MilesAndMoreAddonProps
} from './MilesAndMoreAddon.contracts'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<MilesAndMoreAddon>({
  name: 'MilesAndMoreAddon'
})
export class MilesAndMoreAddon extends Vue {
  @Inject(SiteServiceType)
  public readonly siteService!: ISiteService

  @Prop({ type: String, required: true })
  public readonly id!: MilesAndMoreAddonProps['id']

  @Prop({ type: Number, required: false })
  public readonly points?: MilesAndMoreAddonProps['points']

  @Prop({ type: Number, required: false })
  public readonly pointsLimit?: MilesAndMoreAddonProps['pointsLimit']

  public get milesAndMoreStaticPage (): string | undefined {
    return this.siteService.getActiveSite().properties.milesMore as string | undefined
  }
}

export default MilesAndMoreAddon
